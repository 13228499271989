export const loadScripts = new Promise(async (res, rej) => {
    const $scriptSrc = 'https://cdnjs.cloudflare.com/ajax/libs/script.js/2.5.8/script.min.js';
    const configuratorSrc = 'https://fp-editor-cdn.floorplanner.com/latest.js';

    const headLoader = (scriptUrl) => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('crossorigin', 'anonymous');
      script.setAttribute('src', scriptUrl);
      document.head.appendChild(script);
      return new Promise((res, rej) => {
        script.onload = () => res();
        script.onerror = () => rej();
      });
    };
    const bodyLoader = (scriptUrl) => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('crossorigin', 'anonymous');
      script.setAttribute('src', scriptUrl);
      document.body.appendChild(script);
      return new Promise((res, rej) => {
        script.onload = () => res();
        script.onerror = () => rej();
      });
    };

    try {
      await headLoader($scriptSrc);
      await bodyLoader(configuratorSrc);
      $script.ready('editor', function () {
        res();
      });
    } catch (e) {
      rej(e);
    }
  },
);

export const initializeFloorplanner = async (targetNode = '#fp-editor-container', settings = {}) =>
  await entryPoint(document.querySelector(targetNode), settings?.projectId, settings);
